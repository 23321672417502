<template>
    <div class="dashboard__container--body pb-5">
      <Loader v-if="performingRequest" />
      <iframe v-if="showIframe"
        :src="iframaData"
        id="frame"
        >
      </iframe>
      <div class="mb-5 mt-5 caption">If this page isn't loading properly, you can also access your payroll account at <a href="https://app.everee.com" target="_blank">app.everee.com</a>. Be sure to login/signup using this email address: {{userProfile.email}}</div>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import firebase from 'firebase/app';
import router from '@/router'
import Loader from '@/components/Loader.vue'
const fb = require('../../../firebaseConfig.js')


export default {
  name: 'payrollMenu',
  data: () => ({ 
    iframaData: null,
    showIframe: true,
    performingRequest: false,
  }),
  created() {
    
  },
  async mounted() {
    
    this.createComponentSession()
    this.addEventListenerToIFrame()
  },
  components: {
    Loader,
  },
  computed: {
    ...mapState(['currentUser', 'userProfile']),
    iframe() {
      return this.$el.querySelector("iframe")
    },
  },
  methods: {
    userCheck() {
      if (this.userProfile && this.userProfile.id && this.userProfile.evereeOnboardingComplete) {
        this.$router.push('/account/payroll/onboarding')
      }
      if (this.userProfile && this.userProfile.id && this.userProfile.evereeOnboardingComplete) {
        this.$router.push('/account/details')
      }
    },
    createComponentSession() {
      this.performingRequest = true
        console.log('fetching welcome')
        const createComponentSessionWelcome = firebase.functions().httpsCallable('createComponentSessionHome')
        createComponentSessionWelcome({
          id: this.currentUser.uid
        })
        .then(result => {
          if (result && result.data && result.data.url) {
            this.iframaData = result.data.url
            console.log(result.data)
            this.performingRequest = false
          }
        })
    },
    goBack() {
      router.go(-1) 
    },
    addEventListenerToIFrame() {
      const channel = new MessageChannel();
      const thes = this
      const router = this.$router
      this.iframe.addEventListener("load", function(event) {
      event.target.contentWindow.postMessage("", "*", [channel.port2]);
        channel.port1.onmessage = (event) => {
          console.log(event.data)
          if (event.data.eventType == 'TAX_DOCUMENTS') {
          } 
          else if (event.data.eventType == 'DISMISS' && event.data.errorCode == "EMB-202") {
            console.log('202')
            // thes.userCheck()
            router.push('/account/payroll/onboarding')
          }
          else {

          }
        };
      })
    }
  },
  beforeDestroy () {
    this.showIframe = false;
    delete this.showIframe;
    this.iframaData = null;
    delete this.iframeData;
  }
}

</script>